import { INTEGRATION_PARTNERS } from 'config/types';
import { useDraftOrderQuery } from 'pages/queries/order.queries';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { selectPartner } from 'redux-stores/slices/authSlice';

import { useCacheCurrentLocation } from './useCacheCurrentLocation';

const useAllDayAgent = () => {
  const [searchParams] = useSearchParams();
  const isAgentParam = searchParams.get('isAgent');
  const isAgentKey = localStorage.getItem('isAgent');
  const [isAgent, setIsAgent] = useState(Boolean(isAgentKey));
  const partnerId = process.env.REACT_APP_PARTNER_ID ?? useSelector(selectPartner);
  const isAllDay = partnerId === INTEGRATION_PARTNERS.ALLDAY;
  const isFetchDraftOrder = Boolean(!isAgent && !isAgentParam) && isAllDay;
  const { data: draftOrder, status } = useDraftOrderQuery(isFetchDraftOrder);
  const isDraftOrder = useMemo(() => Boolean(isAllDay && draftOrder?.order), [draftOrder, isAllDay]);
  const { isLocationMissing, getCurrentLocation } = useCacheCurrentLocation(isAgent, isAllDay);
  useEffect(() => {
    // If isAgent received true from URL, set it to localStorage
    if (isAgentParam && !isAgent) {
      localStorage.setItem('isAgent', 'true');
      setIsAgent(true);
    }
  }, [isAgentParam, isAgent]);

  const { computedIsAgent, isAllDayFlow } = useMemo(() => {
    const isAllDayAndAgent = isAllDay && isAgent;
    const shouldShowAllDayFlow = isAllDayAndAgent || isDraftOrder;
    return { computedIsAgent: isAllDayAndAgent, isAllDayFlow: shouldShowAllDayFlow };
  }, [isAllDay, isAgent, isDraftOrder]);

  return {
    isAllDayFlow,
    isAgent: computedIsAgent,
    isDraftOrder,
    isLoadingDraftOrder: status === 'loading' && isFetchDraftOrder,
    isLocationMissing,
    getCurrentLocation,
  };
};

export default useAllDayAgent;
